<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Allocation" vid="allocation_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="allocation_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.allocation_id') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="form.allocation_id"
                                                        :options="subProAprAllocationIdList"
                                                        id="mill_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="bank" vid="bank_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="bank_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.bank') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="form.bank_id"
                                                        :options="bankList"
                                                        id="bank_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="branch" vid="branch_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="branch_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.branch') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="form.branch_id"
                                                        :options="branchList"
                                                        id="bank_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Cheque No" vid="cheque_no" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="cheque_no"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.cheque_no')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="cheque_no"
                                                        v-model="form.cheque_no"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Cheque Date" vid="cheque_date" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="cheque_date"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.cheque_date')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input class="form-control"
                                                                v-model="form.cheque_date"
                                                                placeholder="Select Date"
                                                                id="datepicker"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Fund amount" vid="fund_amount" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fund_amount"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.fund_amount')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input type="number"
                                                        id="fund_amount"
                                                        v-model="form.fund_amount"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Cheque type" vid="check_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="check_type_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.cheque_type') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="form.cheque_type"
                                                        :options="chequeTypeList"
                                                        id="check_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Attachment" vid="attachment">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        :label="$t('document.attachment')"
                                                        label-for="attachment"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{$t('admission_form.photo')}}
                                                    </template>
                                                        <b-form-file
                                                        id="attachment"
                                                        v-on:change="onFileChange"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-file>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                    </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="2"
                                                        label-for="comments"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.comments_en')}}
                                                    </template>
                                                        <b-form-textarea
                                                        id="description"
                                                        v-model="form.comment"
                                                        rows="1"
                                                        max-rows="2"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <ValidationProvider name="Comments (Bn)" vid="comments_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="2"
                                                        label-for="comments_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.comments_bn')}}
                                                    </template>
                                                        <b-form-textarea
                                                        id="description"
                                                        v-model="form.comment_bn"
                                                        rows="1"
                                                        max-rows="2"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundClearanceStore, fundClearanceUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.form = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            branchList: [],
            form: {
                allocation_id: 0,
                bank_id: '',
                branch_id: '',
                cheque_no: '',
                cheque_date: '',
                fund_amount: '',
                cheque_type: '',
                attachment: '',
                comment: null,
                comment_bn: null
            },
            attachment_two: '',
            check_type: [
                {
                    value: 1,
                    text: 'Accounts Payee Cheque',
                    text_bn: 'অ্যাকাউন্ট পে চেক'
                },
                {
                    value: 2,
                    text: 'Post Dated Cheque',
                    text_bn: 'পোস্ট ডেটেড চেক'
                }
            ]
        }
    },
    watch: {
        'form.bank_id': function (newVal) {
            this.branchList = this.getBranchList(newVal)
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        bankList: function () {
            const bankList = this.$store.state.commonObj.bankObj.bankList.filter(item => item.status === 0)
            return bankList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        subProAprAllocationIdList: function () {
            const subProAprAllocationIdList = this.$store.state.incentiveGrant.commonObj.subProAprAllocationIdList.filter(item => item.status === 1)
            return subProAprAllocationIdList.map(item => {
                return { value: item.value, text: item.text }
            })
        },
        chequeTypeList: function () {
            return this.check_type.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        }
    },
    methods: {
        onFileChange (e) {
            this.attachment_two = e.target.files[0]
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            var formData = new FormData()
            Object.keys(this.form).map(key => {
                if (key === 'attachment') {
                    formData.append(key, this.attachment_two)
                } else {
                    formData.append(key, this.form[key])
                }
            })
            if (this.id) {
                formData.append('_method', 'PUT')
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${fundClearanceUpdate}/${this.id}`, formData, config)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, fundClearanceStore, formData, config)
            }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Operation failed!',
                    color: '#D6E09B'
                })
            }
        },
        getBranchList: function (bankId) {
            const branchList = this.$store.state.commonObj.bankObj.branchList.filter(item => item.status === 0 && item.bank_id === bankId)
            return branchList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    }
}
</script>
